import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { SyntheticEvent } from 'react';
import { ColorSchemasEnum } from '../../../../constants/constants';
import { useAutocompleteStyle } from './hooks/useAutocompleteStyle';
import st from './style.module.css';

type Option = { title: string; value: string };

type OnWidgetAutocompleteProps = {
  settingsStyle?: ColorSchemasEnum;
  disabled?: boolean;
  fgColor?: string; // TODO: get type from theme.palette
  label?: string;
  handleChange: (value: Option) => void;
  options: Array<Option>;
  value: Option;
  loading?: boolean;
  handleInputChange?: (e: SyntheticEvent<Element, Event>) => void;
  handleOnOpen?: () => void;
};

const OnWidgetAutocomplete = ({
  settingsStyle,
  disabled,
  fgColor,
  label,
  handleChange,
  options,
  value,
  loading,
  handleInputChange,
  handleOnOpen,
}: OnWidgetAutocompleteProps) => {
  const styles = useAutocompleteStyle({ fgColor, settingsStyle });
  return (
    <Autocomplete
      sx={styles}
      classes={{
        endAdornment: st.endAdornment,
        inputRoot: st.root,
        input: st.input,
        popper: st.popper,
        listbox: st.listbox,
        paper: st.paper,
      }}
      disabled={disabled}
      autoComplete
      disableClearable
      value={value}
      onChange={(_e, val: Option) => {
        handleChange(val);
      }}
      noOptionsText="No objects found"
      loading={loading}
      onOpen={handleOnOpen}
      onInputChange={handleInputChange}
      componentsProps={{
        popper: {
          placement: 'bottom-start',
        },
      }}
      isOptionEqualToValue={(option, val) => {
        if (typeof val === 'string') {
          return val === option.value;
        } else {
          return val.value === option.value;
        }
      }}
      getOptionLabel={(option) => option.title || ''}
      fullWidth={true}
      options={options}
      disablePortal
      renderInput={(params) => <TextField {...params} size="small" placeholder={label} />}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue, {
          insideWords: false,
          requireMatchAll: true,
        });
        const parts = parse(option.title, matches);

        return (
          <li {...props} key={option.value} style={{ textAlign: 'left' }}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};

export default OnWidgetAutocomplete;
