export const mediaServer = window.__pixelConfig.APP_MEDIA_SERVER;
export const highlightSelectedStyle = 'grayscale(0)'; // darkening selected widget

export const NOTIFICATION_TYPES = {
  NOTICE: 'NOTICE',
  ALERT: 'ALERT',
  ALERT_TRIGGERED: 'ALERT_TRIGGERED',
  MESSAGE: 'MESSAGE',
};

export const sizes = {
  tiny: 1,
  small: 2,
  medium: 8,
  big: 16,
};

export const TYPES = {
  COUNTER: 'counter',
  CALCULATOR: 'calculator',
  TIMESERIES: 'timeseries',
  STATISTICS: 'statistics',
  GROUP: 'group',
  WIDGET: 'widget',
  OBJECT: 'object',
  MONITORING_ITEM: 'monitor_item',
  DASHBOARD: 'dashboard',
  REPORT: 'report',
  COLLECTION: 'collection',
};

// TODO: replace with enum ColorSchemasEnum
export const COLOR_SCHEMAS = {
  DARK_ON_LIGHT: 'darkonlight',
  LIGHT_ON_DARK: 'lightondark',
  DARK_ON_TRANSPARENT: 'darkontransparent',
  LIGHT_ON_TRANSPARENT: 'lightontransparent',
};

export enum ColorSchemasEnum {
  DARK_ON_LIGHT = 'darkonlight',
  LIGHT_ON_DARK = 'lightondark',
  DARK_ON_TRANSPARENT = 'darkontransparent',
  LIGHT_ON_TRANSPARENT = 'lightontransparent',
}

export const DOC_URL = 'https://pixel-networks.atlassian.net/wiki/spaces/PCV3/pages/11173966/PixelBoard';

export const OPERATORS = [
  { value: '>', title: '>' },
  { value: '<', title: '<' },
  { value: '=', title: '=' },
  { value: '!=', title: '!=' },
  { value: '>=', title: '>=' },
  { value: '<=', title: '<=' },
  {
    value: 'contains',
    title: 'Contains',
  },
];

// Time in seconds
export const DASHBOARD_STREAM_DATA_TIME = '300';

export const OBJECT_TYPES = [
  { value: 'device', title: 'Device' },
  { value: 'dataset', title: 'Dataset' },
  { value: 'monitor', title: 'Monitor objects' },
  { value: 'stat', title: 'Statistic objects' },
  { value: 'application', title: 'Application' },
];

export const ColorNames = {
  default: 'default',
  purple: 'purple',
  blue: 'blue',
  green: 'green',
  yellow: 'yellow',
  orange: 'orange',
  grey: 'grey',
  red: 'red',
};

export const COLORS_MAP = {
  [ColorNames.orange]: '#FF7518',
  [ColorNames.yellow]: '#FFD600',
  [ColorNames.purple]: '#AA00FF',
  [ColorNames.grey]: '#616161',
  [ColorNames.green]: '#2E7D32',
  [ColorNames.red]: '#D50000',
  [ColorNames.default]: '#333333',
  [ColorNames.blue]: '#2780E3',
};
