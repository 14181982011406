import { TYPES } from '../../../constants/constants';
import { getObjectType } from '../../../utils/objectType';
import useCollectionMenu from './useCollectionMenu';
import useDashboardMenu from './useDashboardMenu';
import useGroupMenu from './useGroupMenu';
import useObjectMenu from './useObjectMenu';
import useReportMenu from './useReportMenu';
import useWidgetMenu from './useWidgetMenu';

export default function useUniversalMenu() {
  const { getMenu } = useObjectMenu();
  const menuBasedOnType = (object, typeEnv) => {
    if (!object) return null;
    if (typeEnv && typeEnv === 'widget') {
      return getMenu(object);
    }

    let type = getObjectType(object.schemaTags, object.linkId);

    const menus = {
      [TYPES.GROUP]: useGroupMenu(object),
      [TYPES.WIDGET]: useWidgetMenu(object),
      [TYPES.TIMESERIES]: useWidgetMenu(object),
      [TYPES.COUNTER]: useWidgetMenu(object),
      [TYPES.CALCULATOR]: useWidgetMenu(object),
      [TYPES.DASHBOARD]: useDashboardMenu(object),
      [TYPES.COLLECTION]: useCollectionMenu(object),
      [TYPES.REPORT]: useReportMenu(object),
      [TYPES.OBJECT]: getMenu(object),
    };
    return menus[type];
  };

  return { menuBasedOnType };
}
