import { PictureAsPdf } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import GetAppIcon from '@mui/icons-material/GetApp';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaletteIcon from '@mui/icons-material/Palette';
import ReplyIcon from '@mui/icons-material/Reply';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import TodayIcon from '@mui/icons-material/Today';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { msg } from '../../../constants/messages';
import ArrowLink from '../../icons/arrowLink';
import ChartIcon from '../../icons/chartIcon';
import TableArrowDown from '../../icons/tableArrowDown';
import TableIcon from '../../icons/tableIcon';

export default function useMenu() {
  const genMenuEditItem = (props) => ({
    icon: <EditIcon />,
    title: msg.sideCard.moreMenu.edit,
    id: 'edit',
    ...props,
  });

  const genMenuSettingsItem = (props) => ({
    icon: <TodayIcon />,
    title: 'Report interval settings',
    id: 'settings',
    ...props,
  });

  const genMenuAccess = (props) => ({
    icon: <SecurityIcon />,
    title: msg.sideCard.moreMenu.changeAccess,
    id: 'change_access',
    ...props,
  });

  const genMenuMuteItem = (props) => ({
    icon: props.muted ? <VolumeUpIcon /> : <VolumeOffIcon />,
    title: props.muted ? 'Unmute' : 'Mute',
    id: 'mute',
    ...props,
  });

  const genMenuCreateCopy = (props) => ({
    icon: <AddIcon />,
    title: msg.sideCard.moreMenu.createCopy,
    id: 'create_copy',
    ...props,
  });

  const genMenuAddNewContainer = (props) => ({
    icon: <AddIcon />,
    title: msg.sideCard.moreMenu.addNewContainer,
    id: 'add_new_group',
    ...props,
  });

  const genMenuSendItem = (props) => ({
    icon: <SendIcon />,
    title: msg.sideCard.moreMenu.sendNotification,
    id: 'send_notification',
    ...props,
  });

  const genMenuShowHistory = (props) => ({
    icon: <HistoryIcon />,
    title: msg.sideCard.moreMenu.showHistory,
    id: 'show_history',
    ...props,
  });
  const genMenuShowControlsHistory = (props) => ({
    icon: <HistoryIcon />,
    title: 'Show controls history',
    id: 'show_controls_history',
    ...props,
  });

  const genMenuDownloadNotifications = (props) => ({
    icon: <GetAppIcon />,
    title: msg.sideCard.moreMenu.downloadNotifications,
    id: 'download_notifications',
    ...props,
  });

  const genCopyUUID = (props) => ({
    icon: <FilterNoneIcon />,
    title: msg.sideCard.moreMenu.copyUUID,
    id: 'copy_uuid',
    ...props,
  });

  const genMenuDelete = (props) => ({
    icon: <DeleteIcon />,
    title: msg.sideCard.moreMenu.delete,
    id: 'delete',
    ...props,
  });

  const genUnlinkItem = (props) => ({
    icon: <ReplyIcon />,
    title: 'Unlink',
    id: 'unlink',
    ...props,
  });

  const getMenuDisableItem = (props) => ({
    icon: props.enabled ? <BlockIcon /> : <CheckCircleOutlineIcon />,
    title: props.enabled ? 'Disable' : 'Enable',
    id: 'enable-disable',
    ...props,
  });

  const getMenuOptionalColors = (props) => ({
    icon: <PaletteIcon />,
    title: 'Optional states',
    id: 'optional-colors',
    ...props,
  });

  const getMenuEditChart = (props) => ({
    icon: <ChartIcon />,
    title: 'Edit charts',
    id: 'edit-charts',
    ...props,
  });

  const getMenuEditTableChart = (props) => ({
    icon: <TableIcon />,
    title: 'Edit charts summary',
    id: 'edit-table-charts',
    ...props,
  });

  const getMenuAlarms = (props) => ({
    icon: <NotificationsIcon />,
    title: 'Alarms',
    id: 'alarms',
    ...props,
  });

  const getSetNull = (props) => ({
    icon: <ClearIcon />,
    title: 'Set null',
    id: 'set-null',
    ...props,
  });

  const getGenPdf = (props) => ({
    icon: <PictureAsPdf />,
    title: 'Save as PDF',
    id: 'export-pdf',
    ...props,
  });

  const getGenCsv = (props) => ({
    icon: <TableArrowDown />,
    title: 'Download as CSV',
    id: 'export-pdf',
    ...props,
  });

  const getLink = (props) => ({
    icon: <ArrowLink />,
    title: 'Link',
    id: 'link',
    ...props,
  });

  return {
    genMenuEditItem,
    genMenuSettingsItem,
    genMenuShowHistory,
    genMenuShowControlsHistory,
    genMenuAccess,
    genMenuMuteItem,
    genMenuCreateCopy,
    genMenuAddNewContainer,
    genMenuSendItem,
    genMenuDownloadNotifications,
    genCopyUUID,
    genMenuDelete,
    getMenuDisableItem,
    getMenuOptionalColors,
    genUnlinkItem,
    getMenuEditChart,
    getMenuAlarms,
    getSetNull,
    getGenPdf,
    getGenCsv,
    getMenuEditTableChart,
    getLink,
  };
}
