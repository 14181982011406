import { useMemo } from 'react';
import { ColorSchemasEnum } from '../../../../../constants/constants';
import { getBackgroundInput } from '../../../utils/getBackgroundInput';

export const useAutocompleteStyle = ({
  fgColor,
  settingsStyle,
}: {
  fgColor: string;
  settingsStyle: ColorSchemasEnum;
}) => {
  return useMemo(
    () => ({
      '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: fgColor,
      },
      '& .MuiInputBase-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
        borderColor: fgColor,
        borderWidth: '1px',
      },
      '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
        borderColor: fgColor,
      },
      '& .MuiInputBase-root': {
        backgroundColor: getBackgroundInput(settingsStyle),
        '& .MuiAutocomplete-input::-webkit-input-placeholder': {
          color: fgColor,
          opacity: 1,
        },
        '&:not(.Mui-disabled) .MuiAutocomplete-input': {
          color: fgColor,
        },

        '&:not(.Mui-disabled) .MuiIconButton-root': {
          color: fgColor,
        },
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.12) !important',
        pointerEvents: 'none',
        borderWidth: '1px',
        color: fgColor,
      },
    }),
    []
  );
};
