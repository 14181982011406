import { ColorSchemasEnum } from '../../../constants/constants';

const getBackgroundInput = (settingsStyle: ColorSchemasEnum) => {
  switch (settingsStyle) {
    case ColorSchemasEnum.DARK_ON_LIGHT:
      return '#fff';
    default:
      return 'rgba(255, 255, 255, 0.15)';
  }
};

export { getBackgroundInput };
