import { gql, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { toast } from 'react-hot-toast';
import hideIfEmpty from '../../../utils/hideIfEmpty';
import AddGroupModal from '../../AddGroupModal';
import AddDashboardModal from '../../dashboard/AddDashboardModal';
import DeleteDashboardModal from '../../DeleteDashboardModal';
import ChangeAccessModal from '../../modals/ChangeAccessModal/ChangeAccessModal';
import RpcProcessModal from '../../modals/RpcProcessModal';
import useHandleCopy from './handlers/useHandleCopy';
import useMenu from './useMenu';

const UNLINK_OBJECT = loader('../../../graphql/UnlinkObject.graphql');

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        id
      }
      clientMutationId
    }
  }
`;

export default function useDashboardMenu(object) {
  const [createExecution] = useMutation(CREATE_EXECUTION);
  const items = useMenu();
  const handleCopy = useHandleCopy();

  const [unlinkObject] = useMutation(UNLINK_OBJECT);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'CopyDashboard',
            objectId: object.id,
            params: {
              UUID: object.id,
              NAME: `${object.name} copy`,
            },
          },
        },
      },
    });
  };

  return [
    items.genMenuAddNewContainer({
      disabled: false,
      handleAction: () => {
        AddGroupModal({})
          .then()
          .catch(() => {});
      },
    }),
    { isDivider: true, name: '', id: 'divider' },
    items.genMenuEditItem({
      disabled: false,
      handleAction: () => {
        AddDashboardModal({
          isEdit: true,
          dashboardId: object.id,
        })
          .then()
          .finally();
      },
    }),
    items.genMenuAccess({
      disabled: false,
      handleAction: () => {
        ChangeAccessModal({ object }).then();
      },
    }),
    items.genMenuCreateCopy({
      disabled: false,
      handleAction: () => {
        RpcProcessModal({
          objectId: object.id,
          successCb: (dashboardId) => {
            if (dashboardId) {
              window.location.href = `/boards/${dashboardId}`;
            }
          },
          callRpc: rpcHandler,
        }).then();
      },
    }),
    items.genCopyUUID({
      disabled: false,
      handleAction: () => handleCopy({ object }),
    }),
    items.genMenuDelete({
      disabled: false,
      handleAction: () => {
        DeleteDashboardModal({
          open: true,
          dashboardId: object.id,
          name: object.name,
        })
          .then(() => {})
          .catch(() => {});
      },
    }),

    ...hideIfEmpty(object.linkId, [
      items.genUnlinkItem({
        handleAction: () => {
          toast
            .promise(
              unlinkObject({
                variables: {
                  linkId: object.linkId,
                },
              }),
              {
                loading: 'Unlinking object...',
                success: () => 'Object unlinked',
                error: (err) => `${err.toString()}`,
              }
            )
            .then(() => {});
        },
      }),
    ]),
  ];
}
