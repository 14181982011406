import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useCallback, useState } from 'react';

const ListItemIconCustom = styled(ListItemIcon)({
  '&': {
    minWidth: '40px !important',
  },
});

const useMoreMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [obj, setObj] = useState(null);
  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenMoreMenu = (e, obj) => {
    setMenuAnchorEl(e.currentTarget);
    setObj(obj);
  };

  const MoreMenu = useCallback(
    (props) => {
      const { items, checkable, handleMenuItemClick } = props;

      const localItems = items?.length ? items : items();

      if (!Boolean(menuAnchorEl)) {
        return null;
      }
      return (
        <Menu
          style={{ flexShrink: 0 }}
          id="more_menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMoreMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {localItems?.map((item, index) => (
            <div key={item.id === 'divider' ? `divider-${index}` : item.id}>
              {item.id === 'divider' && <Divider />}
              {item.id !== 'divider' && (
                <MenuItem
                  data-menu-item={item.id}
                  disabled={item.disabled ?? false}
                  onClick={() => {
                    if (item.handleAction) {
                      item.handleAction(obj);
                      handleCloseMoreMenu();
                    } else {
                      handleMenuItemClick(item.id, obj); // handler in App.js (call on object context menu)
                    }
                  }}
                  style={{
                    justifyContent: 'space-between',
                    height: '40px',
                    width: '246px',
                  }}
                >
                  <Grid style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {item.icon && <ListItemIconCustom>{item.icon}</ListItemIconCustom>}
                    <Grid>
                      <Typography variant="subtitle2" style={{ fontSize: '13px' }}>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    {checkable && (
                      <ListItemIconCustom>
                        {item.checked ? (
                          <CheckIcon data-test-checked="checkeddIcon" fontSize="small" style={{ marginLeft: '40px' }} />
                        ) : (
                          <span style={{ width: '40px' }} />
                        )}
                      </ListItemIconCustom>
                    )}
                  </Grid>
                </MenuItem>
              )}
            </div>
          ))}
        </Menu>
      );
    },
    [menuAnchorEl]
  );

  return {
    MoreMenu,
    openMoreMenu: handleOpenMoreMenu,
    closeMoreMenu: handleCloseMoreMenu,
  };
};

export default useMoreMenu;
