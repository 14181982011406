import { format } from 'date-fns';
import { getNotificationsHistory, getNotificationsHistoryByUserId } from '../media-server';
import downloadBlob from './downloadBlob';

const downloadNotifications = (
  [startDate, endDate]: [startDate: Date | number, endDate: Date | number],
  ids: string[]
) => {
  const data = {
    ids,
    from: format(startDate, 'yyyy-MM-dd 00:00'),
    to: format(endDate, 'yyyy-MM-dd 23:59'),
  };
  return getNotificationsHistory(data).then((res) => {
    downloadBlob(res.data, `PixelBoard_Notifications_${ids}_${data.from}-${data.to}.csv`, res.data.type);
  });
};

export const downloadNotificationsByUserId = async (
  [startDate, endDate]: [startDate: Date | number, endDate: Date | number],
  ids: string[]
) => {
  const data = {
    users: ids,
    from: format(startDate, 'yyyy-MM-dd 00:00'),
    to: format(endDate, 'yyyy-MM-dd 23:59'),
  };
  await getNotificationsHistoryByUserId(data).then((res) => {
    downloadBlob(res.data, `PixelBoard_Notifications_${ids}_${data.from}-${data.to}.csv`, res.data.type);
  });
};

export default downloadNotifications;
