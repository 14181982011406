import { gql, useLazyQuery } from "@apollo/client";
import { orderBy } from "lodash";
import uniqBy from "lodash.uniqby";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import OnWidgetAutocomplete from "../../base/OnWidgetAutocomplete";

export const OBJECTS_QUERY = gql`
  query listDevices($filter: ObjectFilter) {
    objects(orderBy: NAME_ASC, filter: $filter) {
      id
      name
    }
  }
`;

export const LINKED_OBJECTS = gql`
  query linkedObjects($id: UUID!, $schemaId: UUID!) {
    object(id: $id) {
      objectsToObjectsByObject1Id(
        filter: {
          object2: {
            schemaId: { equalTo: $schemaId }
            enabled: { equalTo: true }
          }
        }
      ) {
        id
        object2 {
          id
          name
        }
      }
    }
  }
`;

const SelectObject = ({
  item,
  value,
  settingsLinkedOnly,
  fgColor,
  bgColor,
  label,
  schemaId,
  handleChange,
  settingsStyle,
}) => {
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState(null);
  const [objectsLoad, { loading }] = useLazyQuery(OBJECTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (e) => {
      const objects2 = [
        ...e.objects.map((item) => ({ value: item.id, title: item.name })),
      ];

      if (value) {
        objects2.push(value);
      }
      setObjects(uniqBy(objects2, "value"));
    },
  });

  const [linkedObjects, { loading: loadingLinkedObjects }] = useLazyQuery(
    LINKED_OBJECTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (e) => {
        const linkedObjects = e.object.objectsToObjectsByObject1Id.map(
          (item) => ({
            value: item.object2.id,
            title: item.object2.name,
          })
        );

        if (value) {
          linkedObjects.push(value);
        }
        setObjects(uniqBy(linkedObjects, "value"));
      },
    }
  );

  const handleOnOpen = () => {
    if (schemaId) {
      if (settingsLinkedOnly) {
        linkedObjects({
          variables: {
            id: item.id,
            schemaId: schemaId,
          },
        }).then(() => {});
      } else {
        objectsLoad({
          variables: {
            first: 20,
            filter: {
              schemaId: {
                equalTo: schemaId,
              },
              enabled: {
                equalTo: true,
              },
            },
          },
        }).then(() => {});
      }
    }
  };

  useDebounce(
    () => {
      if (inputValue !== null) {
        setDebouncedValue(inputValue);
      }
    },
    500,
    [inputValue]
  );

  useEffect(() => {
    if (!settingsLinkedOnly) {
      if (schemaId && debouncedValue !== null && debouncedValue !== 0) {
        objectsLoad({
          variables: {
            first: 20,
            filter: {
              schemaId: {
                equalTo: schemaId,
              },
              enabled: {
                equalTo: true,
              },
              name: {
                includesInsensitive: debouncedValue,
              },
            },
          },
        }).then(() => {});
      }
    }
  }, [debouncedValue]);

  const handleInputChange = (e) => {
    if (e && typeof e.currentTarget.value === "string") {
      setInputValue(e.currentTarget.value?.trim());
    }
  };

  return (
    <OnWidgetAutocomplete
      disabled={!schemaId}
      settingsStyle={settingsStyle}
      options={orderBy(objects, ["title"], ["asc"])}
      loading={loading || loadingLinkedObjects}
      handleOnOpen={handleOnOpen}
      value={value}
      fgColor={fgColor}
      bgColor={bgColor}
      label={label}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};

export default SelectObject;
