import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteGroupsModal from '../../../components/modals/DeleteGroupsModal';
import useUniversalMenu from '../../../components/side-card/menu/useUniversalMenu';
import SideList from '../../../components/SideList';

const SideListReports = ({ dashboardLoading, dashboardData }) => {
  let { reportId } = useParams();
  const [itemsChecked, setItemsChecked] = useState([]);
  const { menuBasedOnType } = useUniversalMenu();

  const dashboardName = () => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      return dashboardData.dashboard.name || 'n/a';
    }

    return 'Loading...';
  };

  const getList = (sortType) => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      const groups = dashboardData.dashboard.groups.map((item) => item.group);
      return orderBy(groups, [sortType], ['asc']).map((item) => ({
        ...item,
        link: `/reports/${dashboardData?.dashboard.id}/${item.id}`,
      }));
    }
    return [];
  };

  const headerGroupMenu = [
    {
      icon: <BlockIcon />,
      title: 'Disable',
      id: 'disable',
      disabled: true,
    },
    {
      icon: <DeleteIcon />,
      title: 'Delete',
      id: 'delete-group',
      handleAction: () => {
        DeleteGroupsModal({
          ids: itemsChecked,
          dashboardId: reportId,
          setItemsChecked,
        })
          .then()
          .catch(() => {});
      },
      disabled: false,
    },
  ];

  return (
    <>
      {dashboardLoading && !dashboardData && <LinearProgress />}
      {!dashboardLoading && dashboardData && (
        <SideList
          sideBarWidth={412}
          title={dashboardName()}
          getList={getList}
          itemsChecked={itemsChecked}
          setItemsChecked={setItemsChecked}
          headerMenu={() => menuBasedOnType(dashboardData?.dashboard)}
          headerGroupMenu={headerGroupMenu}
        />
      )}
    </>
  );
};

export default SideListReports;
