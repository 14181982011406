import mediaServerClient from './mediaServerClient';

const token = localStorage.getItem('authToken');

export function getObjectPropertiesHistory(data) {
  return mediaServerClient.post(`/objectpropertieshistory/${token}`, data, {
    responseType: 'blob',
  });
}

export function getHistoryProperties(data) {
  return mediaServerClient.post(`/propertyhistory/${token}`, data, {
    responseType: 'blob',
  });
}

export function getControlsHistory(data) {
  return mediaServerClient.post(`/controlshistory/${token}`, data, {
    responseType: 'blob',
  });
}

export function getNotificationsHistory(data) {
  return mediaServerClient.post(`/notificationshistory/${token}`, data, {
    responseType: 'blob',
  });
}

export function getNotificationsHistoryByUserId(data) {
  return mediaServerClient.post(`/notificationdeliverieshistory/${token}`, data, {
    responseType: 'blob',
  });
}
