import { LinkOff } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { formatValue } from '../../utils/misc';
import { updatedBy } from '../../utils/updatedBy';
import useMoreMenu from '../useMoreMenu';
import useHandleCopy from './menu/handlers/useHandleCopy';
import usePropertyMenu from './property-menu/usePropertyMenu';

const useStyles = makeStyles((theme) => ({
  prop: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },

  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const getValue = (value, units) => {
  if (!value) {
    return 'n/a';
  }
  return `${formatValue(value)} ${units || ''}`;
};

const propertiesAvailableForEdit = [
  'valueValue',
  'valueChart1',
  'valueChart2',
  'valueChart3',
  'valueText',
  'valueAction',
];
const PropListItem = (props) => {
  const { obj, item, tab, linkedObjects } = props;
  const { MoreMenu, openMoreMenu } = useMoreMenu();

  const { getPropertyMenu } = usePropertyMenu();
  const theme = useTheme();
  const classes = useStyles();

  const handleCopy = useHandleCopy();

  return (
    <>
      <MoreMenu items={() => getPropertyMenu({ property: item, object: obj, linkedObjects })}></MoreMenu>
      <ListItem
        key={item.key}
        classes={{
          container: classes.itemToHover,
        }}
        onClick={(e) => {
          handleCopy({
            text: e.currentTarget.getElementsByClassName('value')[0].innerText,
            message: 'Copied',
          });
        }}
      >
        {tab !== 'properties' && item.key !== 'valueValue' && <ListItemIcon></ListItemIcon>}

        {tab === 'general' && item.key === 'valueValue' && (
          <ListItemIcon>
            {obj.objectProperties.find((obj) => obj.key === 'valueValue')?.value === null && <LinkOff />}
            {obj.objectProperties.find((obj) => obj.key === 'valueValue')?.value !== null && (
              <>
                {obj.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'on' && (
                  <NotificationsIcon style={{ color: theme.palette.gray1 }} />
                )}
                {obj.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'triggered' && (
                  <NotificationsActiveIcon style={{ color: theme.palette.red }} />
                )}
              </>
            )}
          </ListItemIcon>
        )}

        <ListItemText
          data-test-object-property={item.updatedAt}
          primary={
            <Typography variant="body1" className={classes.prop}>
              {`${item.spec?.description || item.property}`}:{' '}
              <span className="value">{getValue(item.value, item.spec.units)}</span>
            </Typography>
          }
          secondary={updatedBy(item.updatedAt, item.userByBy)}
        />

        {((tab === 'general' && propertiesAvailableForEdit.includes(item.key)) || tab === 'properties') && (
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              data-test-object={item.key + item.value}
              onClick={(e) => {
                e.preventDefault();

                openMoreMenu(e);
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  );
};

export default PropListItem;
